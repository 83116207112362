import React from "react";
// import interactionPlugin, { DateClickArg } from "@fullcalendar/interaction"
// import '@fullcalendar/core'
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
// import resourceTimeLinePlugin from "@fullcalendar/resource-timeline"
import allLocales from "@fullcalendar/core/locales-all";

import { useDispatch, useSelector } from "react-redux";
import { RootState } from "./store";
import { HolidayEventItem, ReservedRes, RouterProp } from "./data";
import { reserveScheduleAction } from "./store/actions";
import { useHistory } from "react-router";
import { Typography } from "@material-ui/core";
import { getHoliday } from "./api/api";

// import moment from "moment"

import { EventItem } from "./data";

import creater from "./logic/event";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import http from "./api/axiosclient";
import { useInitCalendar } from "./logic/init";

const useStyles = makeStyles(() =>
  createStyles({
    textleft: {
      textAlign: "left",
    },
  })
);

const MyCalendar: React.FC<RouterProp> = (props) => {
  const classes = useStyles();
  const navHistory = useHistory();
  const currentSylist = useSelector((state: RootState) => state.salon.stylist);
  const currentMenue = useSelector((state: RootState) => state.salon.menue);
  const [events, setEvents] = React.useState<Array<EventItem>>();
  const dispatch = useDispatch();
  const isInit = useInitCalendar();

  const minHour = React.useCallback((): string => {
    return currentMenue.IDs?.indexOf(4) !== -1 || currentMenue.IDs.length > 1
      ? "18:00:00"
      : "18:30:00";
  }, [currentMenue.IDs]);

  React.useEffect(() => {
    props.handleTitle(`${currentSylist.Name}`);
  }, [props, currentSylist, currentMenue]);

  React.useEffect(() => {
    const f = async () => {
      let rs: Array<EventItem> = [];
      const res = await http.get<ReservedRes>(
        `reserves/stylists/${currentSylist.ID}`
      );
      for (const r of res.data.reservedList) {
        rs = [
          ...rs,
          { title: "　×", start: r.start, end: r.end, backgroundColor: "#808080" },
        ];
      }
      // console.log(rs);
      let ev: HolidayEventItem[] = [];
      const hevs = await getHoliday("holidays/", "holiday");
      let sevs = await getHoliday("holidays/", `${currentSylist.ID}`);

      if (sevs && hevs) {
        sevs = sevs.filter(
          (sev) => !hevs.find((hev) => hev.start === sev.start)
        );
        ev = [...sevs, ...hevs];

        ev.sort((a, b) => {
          if (a.start < b.start) return -1;
          if (a.start < b.start) return 1;
          return 0;
        });
        setEvents(creater(rs, ev));
      } else {
        setEvents(creater(rs));
      }
    };
    if (!isInit) {
      navHistory.push("/");
    } else {
      f();
    }
  }, [isInit, navHistory, currentSylist]);

  return (
    <div>
      <Typography className={classes.textleft}>
      すでに予約が埋まっている時間帯でも予約できる場合が御座いますので、お電話下さい。
      </Typography>
      <Typography className={classes.textleft}>
        →<a href="tel:090-5893-0119">090-5893-0119</a>
      </Typography>
      <FullCalendar
        // plugins={[dayGridPlugin, interactionPlugin]}
        hiddenDays={[]}
        plugins={[dayGridPlugin, timeGridPlugin]}
        weekends={true}
        initialView="timeGridWeek"
        allDaySlot={false}
        events={events}
        displayEventTime={false}
        eventClick={(arg) => {
          if (arg.event.title === "　◎") {
            if (arg.event.start) {
              dispatch(reserveScheduleAction(arg.event.start.toISOString()));
            }
            navHistory.push("/userinfo");
          }
        }}
        dayHeaderFormat={{
          weekday: "short",
          day: "numeric",
        }}
        locale="ja" // 日本語
        slotDuration={"00:30:00"} // 表示する時間軸の最小値
        slotLabelInterval={"00:30:00"}
        slotLabelFormat={{
          hour: "2-digit",
          minute: "2-digit",
        }}
        contentHeight="auto"
        selectable={false} // 日付選択可能
        headerToolbar={{
          left: "prev,next",
          center: "title",
          right: "today",
        }}
        titleFormat={{
          year: "numeric",
          month: "short",
        }}
        scrollTimeReset={false}
        slotMinTime={"10:00:00"}
        slotMaxTime={minHour()}
        locales={allLocales}
      ></FullCalendar>
    </div>
  );
};

export default MyCalendar;
