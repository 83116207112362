import { ActionTypes } from "./actionType";
import { SalonActionTypes, SalonState } from "./types";

const initState: SalonState = {
    stylist: {
        ID: undefined,
        Name: "",
        IsFree: false,
    },
    menue: {
        IDs: undefined,
        Menus: undefined,
        Shortcuts: undefined,
    },
    schedule: {
        Start: undefined,
    },
    user: {
        ID: undefined,
        Name: "",
        Email: "",
        PhoneNumber: "",
    }
}

export const salonReducer = (state = initState, action: SalonActionTypes): SalonState => {
    switch (action.type) {
        case ActionTypes.setStylist:
            const newStylistState: SalonState = {
                ...state,
                stylist: {
                    ID: action.payload.ID,
                    Name: action.payload.Name,
                    IsFree: action.payload.IsFree,
                }
            }
            return newStylistState
        case ActionTypes.setMenue:
            const newMenueState: SalonState = {
                ...state,
                menue: {
                    IDs: action.payload.IDs,
                    Menus: action.payload.Menus,
                    Shortcuts: action.payload.Shortcuts,
                }
            }
            return newMenueState
        case ActionTypes.reserveSchedule:
            const newScheduleState: SalonState = {
                ...state,
                schedule: {
                    Start: action.payload.Start
                }
            }
            return newScheduleState
        case ActionTypes.setUserInfo:
            const newUserState: SalonState = {
                ...state,
                user: {
                    ID: action.payload.ID,
                    Name: action.payload.Name,
                    Email: action.payload.Email,
                    PhoneNumber: action.payload.PhoneNumber,
                }
            }
            return newUserState
        default:
            return state
    }
}