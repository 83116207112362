import { combineReducers, createStore } from 'redux'
import { salonReducer } from './reducer'

const rootReducer = combineReducers({
    salon: salonReducer,
})

export type RootState = ReturnType<typeof rootReducer>;

const store = createStore(rootReducer)

export default store;
