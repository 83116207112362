import axios, {AxiosRequestConfig} from 'axios'

const url = process.env.REACT_APP_URL

const config: AxiosRequestConfig = {
    baseURL: url,
    headers: {
        "Content-Type": "application/json",
    }
}

const defaultApiClient = axios.create(config)

export default defaultApiClient