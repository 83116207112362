import moment from "moment-timezone";
import { EventItem, HolidayEventItem } from "../data";

const num = (18 - 10) * 2 + 1;
const ds = [...Array(65)].map((_, i) => i);
const ms = [...Array(num)].map((_, i) => i * 30);

const uniq = (ei: Array<EventItem>): Array<EventItem> => {
  const u: Array<EventItem> = [];
  ei.forEach((e, i) => {
    if (i > 0 && ei[i].start === ei[i - 1].start) {
      // console.log("not uniq");
    } else {
      u.push(e);
    }
  });
  return u;
};

const eventCreater = (
  reserved: Array<EventItem> = [],
  holiday: HolidayEventItem[] = []
): Array<EventItem> => {
  const n = moment();
  const notResTime = n.add(30,"m")
  const base = moment.utc([n.year(), n.month(), n.date(), 1]).tz("Asia/Tokyo");
  const end = moment(base).add(2, "M").add(1, "d");
  let ev: Array<EventItem> = [];
  console.log("original: ", reserved.length);
  reserved = uniq(reserved);
  console.log("uniq: ", reserved.length);

  const l = reserved.length;
  let pos = 0;
  for (const d of ds) {
    const b = moment(base).add(d, "d");
    // 一回一回ループを回さなくても良いようにソートすることを考える

    /*
            const days = Object.entries(dic).map(([year, days]) => days.map((day) => 
            {
                return moment().year(year).dayOfYear(day)
            }));

            days.reduce((a, c) => [...c, ...a]).sort((a, b) => {
                if (a.isBefore(b)) return -1
                if (a.isAfter(b)) return 1
                else return 0
            });
        */
    let isHoliday = false;
    if (holiday.find((h) => h.start === b.format("YYYY-MM-DD"))) {
      isHoliday = true;
    }
    if (end.isSameOrBefore(b)) {
      break;
    }
    for (const m of ms) {
      const s = moment(b).add(m, "m");
      const e = moment(s).add(30, "m");
      if (pos < l && reserved[pos].start === s.format()) {
        pos++;
        if (s.isSameOrBefore(notResTime)) {
          continue;
        }
        const r = reserved[pos - 1];
        ev = [
          ...ev,
          {
            id: r.id,
            title: "　×",
            start: s.format(),
            end: e.format(),
            backgroundColor: "#808080",
          },
        ];
      } else {
        if (s.isSameOrBefore(notResTime)) {
          continue;
        }
        if (isHoliday) {
          ev = [
            ...ev,
            {
              id: "",
              title: "　×",
              start: s.format(),
              end: e.format(),
              backgroundColor: "#808080",
            },
          ];
        } else {
          ev = [
            ...ev,
            {
              id: "",
              title: "　◎",
              start: s.format(),
              end: e.format(),
              backgroundColor: "#0062A0",
            },
          ];
        }
      }
    }
  }
  return ev;
};

export default eventCreater;
