import { HolidayEventItem, YearDataRes, ColorTypes } from "../data";
import moment from "moment";
import http from "./axiosclient";

export const getHoliday = async (
  url: string,
  id: string
): Promise<Array<HolidayEventItem> | undefined> => {
  let tmp: Array<HolidayEventItem> = [];
  try {
    const res = await http.get<YearDataRes>(
      url,
      { params: { id: id } } // query
    );
    const yd = res.data.yearDays;
    for (const ys in yd) {
      const y = Number(ys);
      const evs = yd[y].map((d): HolidayEventItem => {
        const start = moment().year(y).dayOfYear(d.yearOfDay);
        return {
          title: "休日",
          start: start.format("YYYY-MM-DD"),
          id: d.id,
          display: "background",
          backgroundColor:
            id === "holiday" ? ColorTypes.pinkAlmond : ColorTypes.normalGray,
        };
      });
      tmp = [...tmp, ...evs];
    }
    tmp.sort((a, b) => {
      if (a.start < b.start) return -1;
      if (a.start < b.start) return 1;
      return 0;
    });
    return tmp;
  } catch (err) {
    alert(err);
  }
};
