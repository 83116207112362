import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import './App.css';
import Menu from './Menue'
import UserInfo from './Userinfo'
import Stylist from './Stylist'
import MyCalendar from './Calendar';
import { AppBar, IconButton, Toolbar, Typography } from '@material-ui/core'
import { ChevronLeft } from '@material-ui/icons'
import {
  BrowserRouter,
  Route,
  Switch,
} from 'react-router-dom'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    offset: theme.mixins.toolbar,
    txt: {
      margin: theme.spacing(1),
    },
    root: {
      flexGrow: 1,
      '& > *': {
        margin: theme.spacing(2),
      }
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },

  })
);

function App() {
  const [title, setTitle] = React.useState("")
  const classes = useStyles();

  React.useEffect( () => {
      document.title = "美容室ueda"
  }, [])

  const handleTitle = React.useCallback((title: string) => {
    setTitle(title)
  }, [setTitle])

  return (
    <div className="App">
      <BrowserRouter>
        <AppBar position='fixed'>
          <Toolbar>
            <IconButton disabled={window.location.pathname === '/'} edge="start" color="inherit" onClick={() => { window.history.back() }}>
              <ChevronLeft></ChevronLeft><Typography variant='body1'>戻る</Typography>
            </IconButton>
            <Typography variant='h6' className={classes.title}>{title}</Typography>
          </Toolbar>
        </AppBar>
        <div className={classes.offset}></div>
        <Switch>
          <Route path="/" exact>
            <Stylist handleTitle={handleTitle}></Stylist>
          </Route>
          <Route path="/menue" exact>
            <Menu handleTitle={handleTitle}></Menu>
          </Route>
          <Route path="/schedule" exact>
            <MyCalendar handleTitle={handleTitle}></MyCalendar>
          </Route>
          <Route path="/userinfo" exact>
            <UserInfo handleTitle={handleTitle}></UserInfo>
          </Route>
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
