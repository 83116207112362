import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { RouterProp, UserInfoData } from './data'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Snackbar, TextField, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { setUserInfoAction } from './store/actions';
import { RootState } from './store';
import { useCookies } from 'react-cookie';
import { useInitUserInfo } from './logic/init';
import { useHistory } from 'react-router';
import http from './api/axiosclient'
import { useForm, useController, UseControllerProps } from 'react-hook-form'
import {Alert} from '@material-ui/lab'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        field: {
            width: '95%',
        },
        textleft: {
            textAlign: 'left',
        },
        alert: {
            margin: theme.spacing(4),
        }
    }),
);

const UserInput: React.FC<UseControllerProps<UserInfoData> & { label: string, halperText: string }> = (props) => {
    const { field, formState: { errors } } = useController(props)
    const classes = useStyles();
    return (
        <>
            <TextField {...field}
                error={errors[props.name] !== undefined}
                helperText={props.halperText}
                label={props.label}
                className={classes.field}
            ></TextField>
        </>
    )
}

const UserInfo: React.FC<RouterProp> = (props) => {
    const { handleSubmit, control, setValue} = useForm<UserInfoData>({
        defaultValues: {
            firstName: '',
            lastName: '',
            email: '',
            phoneNumber: '',
        },
        mode: 'onChange'
    })
    const currentState = useSelector((state: RootState) => state.salon)
    const classes = useStyles();
    const dispatch = useDispatch();
    const [cookies, setCookie] = useCookies(['id', 'firstname', 'lastname', 'email', 'phone'])
    const isInit = useInitUserInfo()
    const navHistory = useHistory();
    const [open, setOpen] = React.useState(false)
    const [comment, setComment] = React.useState("")
    const [snack, setSnack] = React.useState(false)
    const [errmsg, setErrmsg] = React.useState("")


    React.useEffect(() => {
        if (!isInit) {
            navHistory.push('/')
        }
        props.handleTitle("お客様情報")
        if (cookies.id) {
            setValue( 'firstName', `${cookies.firstname}`)
            setValue( 'lastName', `${cookies.lastname}`)
            setValue( 'email', `${cookies.email}`)
            setValue( 'phoneNumber', `${cookies.phone}`)
        }
    }, [isInit, navHistory, cookies, props, setValue])

    const handleClick = (
        data: UserInfoData
    ) => {
        const S = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789"
        const N = 8
        let id = Array.from(Array(N)).map(() => S[Math.floor(Math.random() * S.length)]).join('')
        if (cookies.id) {
            id = `${cookies.id}`
        }
        var option = { path: '/', maxAge: 7776000}
        setCookie('id', id, option)
        setCookie('firstname', data.firstName, option)
        setCookie('lastname', data.lastName, option)
        setCookie('email', data.email, option)
        setCookie('phone', data.phoneNumber, option)
        dispatch(setUserInfoAction(id, data.firstName, data.lastName, data.email, data.phoneNumber))
        console.log(currentState)
        setOpen(true)
    }

    const addAction = React.useCallback(() => {
        const c = currentState
        const f = async () => {
            try {
                const rid = await http.post<string>('reserves',
                    {
                        userName: c.user.Name,
                        stylistID: c.stylist.ID,
                        start: c.schedule.Start,
                        phone: c.user.PhoneNumber,
                        email: c.user.Email,
                        comment: comment,
                        menus: c.menue.Shortcuts,
                    })
                if (rid.status !== 200) {
                    throw new Error(`${c.schedule.Start}にはすでに予約が入っています`)
                }
                await http.post('email',
                    {
                        stylistID: c.stylist.ID,
                        userName: c.user.Name,
                        Start: c.schedule.Start,
                        menue: c.menue.Menus,
                        email: c.user.Email,
                        comment: comment,
                    }
                )
                alert("予約完了")
                console.log(rid.data)
                window.location.replace("/")
            } catch (err) {
                setErrmsg(`${err}`)
                setSnack(true)
                alert(err)
            } finally {
                setOpen(false)
            }
        }
        f()
    }, [currentState, comment])

    const onSubmit = (data: UserInfoData) => { 
        handleClick(data)
    }

    const onSubmitErr = () => {
        setErrmsg("入力に誤りがあります")
        setSnack(true)
    }

    const handleSnackClose = (
            event: React.SyntheticEvent | React.MouseEvent,
            reason?: string,
        ) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setSnack(false);
      };

    return (
        <form onSubmit={handleSubmit(onSubmit, onSubmitErr)}>
            <Typography className={classes.textleft}>予約日:{currentState.schedule.Start?.format('YYYY/MM/DD H:mm')}</Typography>
            <Grid container justifyContent="space-around" spacing={2}>
                <Grid item xs={6}>
                    <UserInput control={control} name='lastName' rules={{ required: true }} label="名字" halperText="名字を入力してください"></UserInput>
                </Grid>
                <Grid item xs={6}>
                    <UserInput control={control} name='firstName' rules={{ required: true }} label="名前" halperText="名前を入力してください"></UserInput>
                </Grid>
                <Grid item sm={6} xs={12}>
                    <UserInput control={control} name='phoneNumber' rules={{ required: true, pattern: /^\d+$/ }} label="電話番号" halperText="電話番号を数字のみで入力して下さい"></UserInput>
                </Grid>
                <Grid item sm={6} xs={12}>
                    <UserInput control={control} name='email' rules={{ required: true, pattern: /^.+@.+$/}} label="e-mail" halperText="e-mailを入力して下さい"></UserInput>
                </Grid>
                <Grid item sm={12} xs={12}>
                    <TextField
                        value={comment}
                        helperText={"ご要望が有りましたら記載してください"}
                        multiline
                        id="client-other"
                        label="ご要望"
                        className={classes.field}
                        onChange={(ev) => setComment(ev.target.value)}
                    ></TextField>
                </Grid>
                <Grid item xs={5}>
                    <Button variant="outlined" onClick={() => navHistory.goBack()}>キャンセル</Button>
                </Grid>
                <Grid item xs={5}>
                    <Button variant="contained" type='submit' color="primary">予約する</Button>
                </Grid>
            </Grid>
            <Dialog open={open}>
                <DialogTitle>予約情報</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Typography className={classes.textleft}>スタイリスト:{currentState.stylist.Name}</Typography>
                        <Typography className={classes.textleft}>コース:{currentState.menue.Menus?.join(', ')}</Typography>
                        <Typography className={classes.textleft}>予約日:{currentState.schedule.Start?.format('YYYY/MM/DD H:mm')}</Typography>
                        <Typography className={classes.textleft}>お名前:{currentState.user.Name}</Typography>
                        <Typography className={classes.textleft}>お電話:{currentState.user.PhoneNumber}</Typography>
                        <Typography className={classes.textleft}>メール:{currentState.user.Email}</Typography>
                        <Typography className={classes.textleft}>ご要望:{comment}</Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)} variant="outlined">項目を選び直す</Button>
                    <Button onClick={addAction} variant="contained" color="primary">予約を送信</Button>
                </DialogActions>
            </Dialog>
            <Snackbar
                open={snack}
                autoHideDuration={3000}
                onClose={handleSnackClose}
                anchorOrigin={
                    {
                        horizontal: 'center',
                        vertical: 'bottom',
                    }
                }
            >
                <Alert severity="error" className={classes.alert}>{errmsg}</Alert>
            </Snackbar>
        </form>
    )
}

export default UserInfo