import React from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { MenueMaps, RouterProp } from './data'
import { List, ListItem, ListItemText, ListItemIcon, Divider, Checkbox, Button } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { setMenueAction } from './store/actions'
import { RootState } from './store';
import { useHistory } from 'react-router';
import { useInitMenue } from './logic/init';
import http from './api/axiosclient'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        price: {
            textAlign: "right"
        },
        menue: {
            padding: theme.spacing(2)
        },
        btn: {
            margin: theme.spacing(2),
            width: '50%',
        }
    }),
);

const Menue: React.FC<RouterProp> = props => {
    const navHistory = useHistory();
    const currentSylist = useSelector((state: RootState) => state.salon.stylist)
    const classes = useStyles()
    const [disable, setDisable] = React.useState(false);
    const [data, setData] = React.useState<MenueMaps>();
    const dispatch = useDispatch();
    const isInit = useInitMenue();

    React.useEffect(() => {
        props.handleTitle(`コース選択(${currentSylist.Name})`)
    })


    React.useEffect(() => {
        if (!isInit) {
            window.location.replace("/")
        }
        const f = async (id: string) => {
            const res = await http.get<MenueMaps>(
                `stylists/${id}/menues/`
            )
            if (currentSylist.IsFree) {
                const index = res.data.menueList.findIndex(m => m.order === 1)
                if (index !== -1) {
                    res.data.menueList[index].priceStr = '5060円'
                }
            }
            setData(res.data)
        };

        if (currentSylist.ID) {
            const id = currentSylist.ID
            f(id)
        }
    }, [isInit, currentSylist]);

    const [checked, setChecked] = React.useState<number[]>([])
    const [menus, setMenus] = React.useState<string[]>([])
    const [shortcuts, setShortcuts] = React.useState<string[]>([])

    const handleClickBox = (val: number, menu: string, shortcut: string) => () => {
        const ind = checked.indexOf(val);
        const newChecked = [...checked];
        const newMenus = [...menus];
        const newShortcuts = [...shortcuts]
        if (ind === -1) {
            newChecked.push(val)
            newMenus.push(menu)
            newShortcuts.push(shortcut)
         } else {
             newChecked.splice(ind, 1);
             const mind = menus.indexOf(menu)
             newMenus.splice(mind, 1)
             const sind = shortcuts.indexOf(shortcut)
             newShortcuts.splice(sind, 1)
         }
        setChecked(newChecked)
        setMenus(newMenus)
        setShortcuts(newShortcuts)
    }

    const handleClick = React.useCallback(() => {
        setDisable(true)
        dispatch(setMenueAction(checked, menus, shortcuts))
        navHistory.push("/schedule")
    }, [dispatch, navHistory, checked, menus, shortcuts])


    return (
        <>
            <List component="nav">
                {
                    data?.menueList.map((m) => {
                        return (
                            <div key={`menue-${m.order}`}>
                                <ListItem button onClick={handleClickBox(m.order, m.menue, m.shortcut)} disabled={disable}>
                                    <ListItemIcon>
                                        <Checkbox
                                            edge="start"
                                            checked={checked.indexOf(m.order) !== -1}
                                            tabIndex={-1}
                                            disableRipple
                                        ></Checkbox>
                                    </ListItemIcon>
                                    <ListItemText>
                                        {m.menue}
                                    </ListItemText>
                                    <ListItemText className={classes.price}>
                                        {m.priceStr !== '0円' ? m.priceStr + '　' : ''}
                                    </ListItemText>
                                </ListItem>
                                <Divider></Divider>
                            </div>
                        )
                    })
                }
            </List>
            <Button className={classes.btn} variant="contained" color="primary" onClick={handleClick} disabled={checked.length === 0}>決定</Button>
        </>
    )
}

export default Menue