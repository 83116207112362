export type BtnProp = {
    label: string;
    handler: () => void;
}
export interface YearDataRes {
    yearDays: {
        [year: number]: Array<{
            id: string,
            yearOfDay: number,
        }>
    }
}

export const ColorTypes = {
    normalGray: "#202020",
    pinkAlmond: "#e3acae",
} as const

type Unpacked<T> = T extends { [K in keyof T]: infer U } ? U : never

export interface HolidayEventItem {
    title: '休日'
    id: string
    start: string
    display: 'background'
    backgroundColor: Unpacked<typeof ColorTypes>
}

export type RouterProp = {
    handleTitle: (title: string) => void
}

export type TestApiRes = {
    id: string;
    msg: string;
}

export interface UserInfoData {
    firstName: string;
    lastName: string;
    phoneNumber: string;
    email: string;
}

export interface MenueMaps {
    menueList: Array<MenueValue>
}
export interface MenueValue {
    order: number
    menue: string
    priceStr: string
    buffer: number
    shortcut: string
}

export interface EventItem {
    id?: string
    count?: number,
    countMax?: number
    title: "　◎" | "　×" | "1" | "2"
    start: string
    end: string
    backgroundColor: "#0062A0" | "#808080"
}

export type ReservedRes = {
    reservedList: Array<reservedDataRes>
}

type reservedDataRes = {
    reservedCountMax: number,
    reservedCount: number,
    start: string,
    end: string
}

export type StylistRes = {
    stylists: Array<stylist>
}

type stylist = {
    name: string
    id: string
}