import { ActionTypes } from "./actionType";
import { SalonActionTypes } from "./types";
import moment from 'moment'

export const setStylistAction = (ID: string, name: string, isFree: boolean = false): SalonActionTypes => {
    return {
        type: ActionTypes.setStylist,
        payload: {
            ID: ID,
            Name: name,
            IsFree: isFree,
        }
    }
}

export const setMenueAction = (IDs: number[], menus: string[], shortcuts: string[]): SalonActionTypes => {
    return {
        type: ActionTypes.setMenue,
        payload: {
            IDs: IDs,
            Menus: menus,
            Shortcuts: shortcuts,
        }
    }
}

export const reserveScheduleAction = (start: string): SalonActionTypes => {
    return {
        type: ActionTypes.reserveSchedule,
        payload: {
            Start: moment(start)
        },
    }
}

export const setUserInfoAction = (id: string, firstName: string, lastName: string, email: string, phoneNumber: string): SalonActionTypes =>{
    return {
        type: ActionTypes.setUserInfo,
        payload: {
            ID: id,
            Name: `${lastName} ${firstName}`,
            Email: email,
            PhoneNumber: phoneNumber,
        }
    }
}