import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";
import { setStylistAction } from "../store/actions";

export const useInitMenue = (): boolean => {
    const cookies = useCookies(['id'])
    const dispatch = useDispatch();
    const sylist = useSelector((state: RootState) => state.salon.stylist);
    if (!sylist.ID) {
        return false
    }
    if (sylist.ID === '99') {        
        // ２回目以降は 岡越に設定 cookieのidの値を持っているかで判断
        if (cookies[0].id) {
            dispatch(setStylistAction('2', sylist.Name))
        } else {
            dispatch(setStylistAction('1', sylist.Name))
        }
    }
    return true
}

export const useInitCalendar = (): boolean => {
    const sylist = useSelector((state: RootState) => state.salon.stylist.ID);
    const menue = useSelector((state: RootState) => state.salon.menue.IDs);
    if (sylist && menue) {
        return true
    } else {
        return false
    }
}

export const useInitUserInfo = (): boolean => {
    const sylist = useSelector((state: RootState) => state.salon.stylist.ID);
    const menue = useSelector((state: RootState) => state.salon.menue.IDs);
    const schedule = useSelector((state: RootState) => state.salon.schedule.Start);
    if (sylist && menue && schedule) {
        return true
    } else {
        return false
    }
}