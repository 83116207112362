import React from 'react'
import { Button, List, ListItem, ListItemText, ListItemIcon, Divider } from '@material-ui/core';
import { ChevronRight } from '@material-ui/icons';
import { useDispatch } from 'react-redux';
import { setStylistAction } from './store/actions';
import { RouterProp } from './data'
import { useHistory } from 'react-router-dom'
import { useCookies } from 'react-cookie';
import http from './api/axiosclient'
import { StylistRes } from './data';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    offset: theme.mixins.toolbar,
    buttom: {
      textAlign:'right',
      width:"100%",
      position: "absolute",
      bottom: 0,
    },
    txt: {
      margin: theme.spacing(1),
    },
  })
);

const Stylist: React.FC<RouterProp> = props => {
    const [stylists, setStylists] = React.useState<{ id: string, name: string }[]>([])
    const [disable, setDisable] = React.useState(false);
    const cookies = useCookies(['id'])
    const dispatch = useDispatch();
    const navHistory = useHistory();
    const classes = useStyles();

    const getID = React.useCallback((id: string): [string, boolean] => {
        if (id === "-1") {
            if (cookies[0].id) {
                return ["2", true]
            } else {
                return ["1", true]
            }
        }
        return [id, false]
    }, [cookies])

    const handleClick = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        index: number,
        name: string,
    ) => {
        setDisable(true)
        const [id, isFree] = getID(`${index}`)
        dispatch(setStylistAction(id, name, isFree))
        navHistory.push("/menue")
    }
    React.useEffect(() => {
        props.handleTitle("スタイリスト選択")
    }, [props])

    React.useEffect(() => {
        const f = async () => {
            const res = await http.get<StylistRes>("stylists/")
            setStylists(res.data.stylists)
        }
        f()
    }, [])
    return (
        <>
        <List component="nav">
            {stylists.map((s) => {
                return (<div key={`stylist-${s.id}`}>
                    <ListItem button onClick={(event) => handleClick(event, Number(s.id), s.name)} disabled={disable} key={"stylist-0"}>
                        <ListItemText primary={s.name}></ListItemText>
                        <ListItemIcon>
                            <ChevronRight></ChevronRight>
                        </ListItemIcon>
                    </ListItem>
                    <Divider></Divider>
                </div>)
            })}
            <ListItem button onClick={(event) => handleClick(event, -1, "指名なし")} disabled={disable} key={"stylist-2"}>
                <ListItemText primary="フリー (指名無し)"></ListItemText>
                <ListItemIcon>
                    <ChevronRight></ChevronRight>
                </ListItemIcon>
            </ListItem>
        </List>
      <div className={classes.buttom}>
        <Button color='primary' href="policy.pdf" size='small' className={classes.txt}>プライバシーポリシー</Button>
      </div>
      </>
    )
}

export default Stylist